.contents-contract-user {
  // width: 525px;
  // height: 180px;
  background-color: #f8f8fa;
  border-radius: 20px;
}

.contents-detail {
  height: 100%;
}

.contents-slash {
  position: relative;
  top: -14px;
  height: 100%;
}
