.product-theme {
  height: auto;
  &__selected {
    width: 134px;
    height: 80px;
    border: 1px solid map-get($grays, "400");
    border-radius: 4px;
    margin-right: 10px;
  }
  &__colors {
    border: 1px solid map-get($grays, "400");
    width: fit-content;
    padding: 2px;
    display: grid;
    gap: 2px;
    grid-template-columns: repeat(5, 36px);
    .colors {
      border: 1px solid map-get($grays, "400");
      width: 100%;
      height: 36px;
      cursor: pointer;
    }
  }
  .redg01 {
    background: linear-gradient(180deg, #e14a64 0%, #e1774a 100%);
  }
  .redg02 {
    background: linear-gradient(180deg, #cd6226 0%, #cd9026 100%);
  }
  .redg03 {
    background: linear-gradient(180deg, #eb4848 0%, #eb5904 100%);
  }
  .redg04 {
    background: linear-gradient(180deg, #8237a6 0%, #a6377a 100%);
  }
  .greyg01 {
    background: linear-gradient(180deg, #212121 0%, #363636 100%);
  }
  .greeng01 {
    background: linear-gradient(180deg, #176661 0%, #176658 100%);
  }
  .greeng02 {
    background: linear-gradient(180deg, #248654 0%, #4c9b54 100%);
  }
  .blueg01 {
    background: linear-gradient(180deg, #207eb3 0%, #2099b3 100%);
  }
  .blueg02 {
    background: linear-gradient(180deg, #344dcb 0%, #497fb1 100%);
  }
  .blueg03 {
    background: linear-gradient(180deg, #192c4d 0%, #24517a 100%);
  }
}

.Product_status {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 51px;
  max-width: 51px;
  padding: 4px 16px;
  height: 24px;
  min-height: 24px !important;
  color: $white;
  font-size: 1.2rem;
  white-space: nowrap;
  line-height: 1.6rem;
  border-radius: 4px;
  &.ENABLED {
    background-color: $system_linked; // 공개
  }
  &.DISABLED {
    background-color: $text_primary4; // 비공개
  }
  &.LINK_ENABLED {
    background-color: $system-green; // 링크
  }
}

.product_type_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 51px;
  max-width: 51px;
  padding: 4px 16px;
  height: 24px;
  min-height: 24px !important;

  font-size: 1.2rem;
  white-space: nowrap;
  line-height: 1.6rem;
  border-radius: 4px;
  font-weight: 600;

  letter-spacing: -0.04em;
  text-align: left;

  &.FULL_COURT {
    background-color: rgba(83, 50, 208, 0.07); // 공간 임대
    color: #5332d0;
  }
  &.TIME_COURT {
    background-color: rgba(38, 166, 135, 0.07); // 공간 예약
    color: #26a687;
  }
  &.NON_RESIDENT_OFFICE {
    background-color: rgba(33, 123, 206, 0.07); // 비상주
    color: $system_linked;
  }
  &.MAINTENANCE_FEE {
    background-color: rgba(118, 118, 118, 0.07); // 관리비
    color: $text-primary3;
  }
  &.DIGITAL_PRODUCT {
    background-color: rgba(249, 105, 96, 0.07); // 디지털
    color: #f96960;
  }
  // FULL_COURT: "공간 임대", //F2
  // TIME_COURT: "공간 예약", //S2
  // NON_RESIDENT_OFFICE: "비상주", //V
  // MAINTENANCE_FEE: "관리비", //M
  // DIGITAL_PRODUCT: "디지털", //D
}

.contents-container__grid-contents div.grey_text_12 span {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.48px;
  color: $text_primary3;
}

.product_table_td_img {
  width: 43px;
  height: 43px;
}

.parking_space {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  > div {
    display: flex;
    justify-items: start;
    align-items: start;
    > p {
      width: 145px;
    }
  }

  .build_contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }

  .gray-board {
    display: flex;
    width: 925px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 10px;
    border: 1px solid var(--bg-dark, #f2f4f6);
    background: var(--single-mono-bg-light, #f8f8fa);

    .building_name {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 4px;

      color: var(--single-mono-text-primary-03, #767676);
      /* Kor/Body/BD5 14sb */
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.56px;
    }
    .building_text {
      color: var(--single-mono-text-primary-01, #191919);
      /* ENG body/BD6 14rg */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .setting-warper {
    flex: 1;
  }

  .setting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  .setting p {
    display: block;
    color: var(--text-primary-1, #191919);
    /* Kor/Body/BD6 14rg */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.56px;
  }
}

.parking_setting_modal .medium {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.parking_setting_modal .popup-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 24px;
  font-weight: 700;
}

.parking_setting_modal .subtitle-wrapper {
  height: 130px;
  width: 100%;
}

.parking_setting_modal .subtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
}

.parking_setting_modal .subtitle .BaseCheckbox {
  height: 40px;
  display: flex;
  align-items: center;
}

.parking_setting_modal .medium-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 540px;
  align-items: flex-end;
  gap: 30px;
  padding: 0px 30px 30px;
  position: relative;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
}

.parking_setting_modal .medium-button {
  display: flex;
  gap: 10px;
}

.w-full {
  width: 100% !important;
}

.product-w-full {
  width: 1070px !important;
}

.left-446 {
  left: 446px;
}

.parking_setting_modal {
  .contents-wrapper {
    width: 100%;
    .input-warp {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      padding: 0px 30px 30px 30px;
      > div:first-child {
        display: flex;
        align-items: center !important;
        width: auto;
      }
    }
  }
}

.hiddenPrice {
  display: none !important;
}
.beforeDate {
  color: $text-primary3;
}

.gray-board-default {
  scroll-padding: 50px;

  margin-top: 20px;
  padding: 30px;
  background-color: $bg_light;
  border-radius: 10px;
  max-height: 424px;

  display: flex;
  flex-direction: row;
  gap: 14px;
  .wrap:nth-child(1) {
    scroll-margin-top: 100px; /* 스크롤 마진 */
    width: 190px;
  }
  .wrap:nth-child(2) {
    width: 190px;
  }
  .wrap:nth-child(3) {
    width: 336px;
    gap: 10px;
    overflow-y: auto;
  }
  .wrap:nth-child(4) {
    width: 267px;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-weight: 600;
      font-size: 1.4rem;
      color: $text_primary3;
    }
    .text {
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
}

.ic_chevron_down_b {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_chevron_down_b.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

.ic_chevron_up_b {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_chevron_down_b.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: rotate(180deg);
  cursor: pointer;
  content: " ";
  padding: 10px;
}

.chip-case-product input[type="checkbox"] {
  & + label {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    border: 1px solid $line-light;
    display: inline-block;
    background-color: $white;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      border: 1px solid $text-primary;
      span {
        color: $text-primary;
      }
    }

    span {
      font-size: 1.2rem !important;
      line-height: 16px;
      color: $text-primary;
      margin: 0px !important;
    }
  }

  & + label .base-checkbox {
    display: none;
  }

  &:checked + label {
    background-image: unset;
    background-color: $text-primary;
    border: 1px solid $text-primary;
    span {
      color: $white;
    }
  }

  &:disabled + label {
    background-color: $line-light;
    border: 1px solid $line-light;
    span {
      color: $white;
    }
  }

  &:checked:disabled + label {
    background-color: $line-dark;
    border: 1px solid $line-dark;
    span {
      color: $white;
    }
  }
}

.inner-tab-table-closed-period {
  margin-bottom: 10px;
  .base-table {
    &__td {
      min-height: 50px;
      height: auto;
    }
    &__td,
    .multi-line {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
    }
    .multi-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .base-pagination {
    padding: 0;
  }
}

.modal_table_layout {
  overflow-y: scroll;
}

.history-detail-container {
  .common-info {
    background-color: #fafbfc;
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 24px;
    border: 1px solid #dfe1e6;

    .info-item {
      margin-bottom: 12px;
      font-size: 15px;
      display: flex;
      align-items: center;

      .label {
        font-weight: 600;
        margin-right: 12px;
        color: #172b4d;
        min-width: 80px;
      }
    }

    .total-changes {
      margin-top: 16px;
      padding-top: 12px;
      border-top: 1px solid #dfe1e6;
      font-size: 14px;
      color: #42526e;
      font-weight: 500;
    }
  }

  .changes-list {
    .change-item {
      border: 1px solid #dfe1e6;
      border-radius: 3px;
      padding: 20px;
      margin-bottom: 12px;
      background-color: #fff;
      box-shadow: 0 1px 1px rgba(9, 30, 66, 0.13);
      position: relative;

      .change-header {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        .index {
          padding: 2px 8px;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.5px;
          background-color: #f4f5f7;
          color: #42526e;
        }

        .change-type {
          padding: 2px 8px;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          flex-shrink: 0;

          &.type-replace {
            background-color: #e3fcef;
            color: #006644;
          }

          &.type-add {
            background-color: #eae6ff;
            color: #403294;
          }
        }

        .change-path {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 13px;
          padding: 2px 8px;
          background-color: #f4f5f7;
          border-radius: 3px;

          .matched-path {
            color: #172b4d;
            font-weight: 500;
          }

          .original-path {
            color: #6b778c;
            font-size: 12px;

            &::before {
              content: "(";
              margin-right: 2px;
            }

            &::after {
              content: ")";
              margin-left: 2px;
            }
          }
        }
      }

      .change-content {
        .change-origin,
        .change-value {
          margin-bottom: 12px;
          font-size: 13px;
          padding: 8px 12px;
          border-radius: 3px;
          border: 1px solid #dfe1e6;
        }

        .change-origin {
          background-color: #ffebe6;
          border-color: #ffebe6;

          .label {
            font-weight: 500;
            margin-right: 8px;
            color: #bf2600;
            display: inline-block;
            margin-bottom: 0;
          }

          span:not(.label) {
            color: #42526e;
          }
        }

        .change-value {
          background-color: #e3fcef;
          border-color: #e3fcef;

          .label {
            font-weight: 500;
            margin-right: 8px;
            color: #006644;
            display: inline-block;
            margin-bottom: 0;
          }

          span:not(.label) {
            color: #172b4d;
          }
        }
      }
    }
  }

  .object-content {
    padding-left: 16px;

    .object-item {
      margin: 4px 0;
      font-size: 13px;
      line-height: 1.4;

      .object-key {
        font-weight: 500;
        color: #42526e;
      }

      .object-value {
        color: #172b4d;
      }
    }
  }
}
