// 공통
input[type="checkbox"].checkbox,
input[type="radio"].radio {
  display: none;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: transparent url("../../images/ic_input_number.svg") no-repeat center center;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 7px;
}

.validation-text {
  font-size: 1.2rem !important;
  color: $system-red;
  white-space: nowrap;
  padding-top: 4px;
  padding-left: 10px;
}

.validation-text-xl {
  font-size: 1.5rem !important;
  color: $system-red;
  white-space: nowrap;
  padding-top: 4px;
  padding-left: 10px;
}

.base-select-input {
  display: flex;
  border-radius: 4px;
  box-sizing: content-box;

  &:hover {
    .base-input {
      input {
        border-color: $text-primary;
      }
    }

    .base-select {
      .react-select__control {
        border-color: $text-primary;
      }
    }
  }

  .base-input {
    width: 140px;
    input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;

      &:focus,
      &:focus:hover {
        outline: none;
        border-left: none;
      }
    }
  }
  &__focus {
    .base-input {
      input {
        border-color: $text-primary;
      }
    }

    .base-select {
      .react-select__control {
        border-color: $text-primary;

        &--is-focused {
          border-color: $text-primary;
          box-shadow: unset;
        }
      }
    }
  }

  .base-select {
    width: 95px;
    .react-select__control {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-select__value-container {
      padding-right: 0;
    }
  }
}

.base-select-input.readonly {
  &:hover {
    .base-input {
      input {
        color: $text-primary4;
        background-color: $bg_light;
        border-color: $line_light;
      }
    }

    .base-select {
      .react-select__control {
        color: $text-primary4;
        background-color: $bg_light;
        border-color: $line_light;
      }
    }
  }
}

.base-search-input {
  // BaseInputWithSearch
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: content-box;

  .react-select__control {
    transition: unset;
  }

  &:hover {
    .base-input {
      input {
        border-color: $text-primary;
      }

      .base-search-btn {
        border-top-color: $text_primary;
        border-right-color: $text_primary;
        border-bottom-color: $text_primary;
      }
    }

    .base-select {
      .react-select__control {
        border-color: $text-primary;
      }
    }
  }

  &__focus {
    .base-input {
      input {
        border-color: $text-primary;
      }

      .base-search-btn {
        border-top-color: $text_primary;
        border-right-color: $text_primary;
        border-bottom-color: $text_primary;
      }
    }

    .base-select {
      border-color: $text-primary;
      .react-select__control {
        border-color: $text-primary;

        &--is-focused {
          border-color: $text-primary;
          box-shadow: unset;
        }
      }
    }
  }

  &:disabled {
    color: $text-primary4;
    background-color: $bg_light;
    border-color: $line_light;
    &:focus,
    &:focus:hover {
      border-color: $line_light;
    }
  }

  .base-input {
    input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;

      &:focus,
      &:focus:hover {
        outline: none;
        border-color: $text-primary;
        border-left: none;

        .base-search-btn {
          border-top-color: $text_primary;
          border-right-color: $text_primary;
          border-bottom-color: $text_primary;
        }
      }
    }
  }

  .base-select {
    .react-select__control {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-select__value-container {
      padding-right: 0;
    }
    .react-select__control--is-disabled {
      background-color: $white;
      .react-select__indicator {
        opacity: 0.3;
      }
    }
  }
}

// pc
.base-input {
  width: 100%;
  position: relative;

  &.text-center {
    input {
      text-align: center;
      font-size: 1.8rem;
      &::placeholder {
        font-size: 1.8rem;
      }
    }
  }

  &.text-right {
    input {
      text-align: right;
      &::placeholder {
        text-align: left;
      }
    }
  }

  input {
    min-height: 40px;
    max-height: 40px;
    width: 100%;
    height: 40px;
    text-align: inherit;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $line_light;
    padding: 0 10px;
    color: $text_primary;
    font-size: 1.4rem;
    font-family: "Apple SD Gothic Neo", sans-serif;

    &::placeholder {
      color: $text_primary4;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: -0.56px;
    }

    &:focus,
    &:focus:hover {
      outline: none;
      border: 1px solid $text_primary;
    }

    &:hover {
      border-color: $text_primary;
    }

    &:read-only {
      background-color: $bg_light;
      border-color: $line_light;
      &:focus,
      &:focus:hover {
        border-color: $line_light;
      }
    }
    &:disabled {
      color: $text-primary4;
      background-color: $bg_light;
      border-color: $line_light;
      &:focus,
      &:focus:hover {
        border-color: $line_light;
      }
    }
  }

  .base-select {
    // base-input에 select가 들어있는 예외케이스 처리( BaseInputWithSearch )
    input {
      height: unset;
      min-height: unset;
      max-height: unset;
    }
  }

  &.base-search {
    input {
      padding-right: 70px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      text-overflow: unset;

      &:hover,
      &:focus {
        & ~ .base-search-btn {
          border-top-color: $text_primary;
          border-right-color: $text_primary;
          border-bottom-color: $text_primary;
        }
      }
    }

    .base-clear-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;

      & ~ input {
        padding-right: 65px;
      }
    }
  }

  &.base-right-icon {
    input {
      padding-right: 34px;
    }
  }

  .base-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid $line_light;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .base-icon-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.ic-eyes-open {
      height: 100%;
      width: 40px;
      background-image: url("../../images/ic_pw_open.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.ic-eyes-close {
      height: 100%;
      width: 40px;
      background-image: url("../../images/ic_pw_close.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .base-clear-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  &.default {
    input:read-only {
      background-color: $white;
    }
  }
}

// 데이터피커
.base-datepicker {
  &.full-datepicker {
    .react-datepicker__time-container {
      position: absolute;
      right: -120px;
      box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.12);
      border-left: 0;
    }
  }

  &.react-datepicker {
    border: 0;
    box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.12);
    border-radius: 8px;
    box-sizing: border-box;
  }

  .react-datepicker {
    &__header {
      background-color: map-get($grays, "900");
      color: $white;
      border-top-right-radius: 8px !important;
      border-top-left-radius: 8px !important;

      //====== 해리 추가 ======
      &.react-datepicker-year-header {
        font-size: 1.4rem;
        font-weight: 400;
        // padding-top: 2px;
        color: $white;
      }
      // ====================
    }

    &__month {
      z-index: 1;
      position: relative;
      &-container {
        width: 205px;
      }
    }

    //====== 해리 추가 ======
    &__month-wrapper {
      margin: 10px 0;
      font-size: 12px;
    }
    // ====================
    &__week {
      position: relative;
      overflow: hidden;
    }

    &-time__header {
      color: $white;
      font-size: 1.6rem;
      font-weight: 400;
    }

    &__time {
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      &-container {
        width: 120px;
      }

      &-box {
        width: 120px !important;
      }

      &-list-item {
        font-size: 1.4rem;

        &--selected {
          background-color: map-get($grays, "700") !important;
        }
      }
    }

    &__current-month {
      font-size: 1.4rem;
      font-weight: 400;
      padding-top: 2px;
      color: $white;
    }

    &__triangle {
      display: none;
    }

    &__day {
      font-size: 1.1rem;
      font-family: "Roboto", sans-serif;
      border-radius: 100%;
      width: 22px;
      height: 22px;
      padding: 4px;
      line-height: 1.2;
      border: 1px solid transparent;

      &:hover:not(.react-datepicker__day--range-start) {
        border-radius: 100%;
        background-color: $white;
        border: 1px solid $text-primary;
        color: $text-primary;
      }

      // selecting-range는 선택 전 hover상태의 범위
      &--in-selecting-range {
        position: relative;
      }
      &--in-selecting-range:not(
          .react-datepicker__day--selecting-range-start,
          .react-datepicker__day--selecting-range-end .react-datepicker__day--inrange,
          .react-datepicker__month-text--in-range,
          .react-datepicker__quarter-text--in-range,
          .react-datepicker__year-text--in-range
        ) {
        background-color: $bg_dark !important;
        box-shadow: unset;
        color: $text-primary;
      }
      &--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
        background-color: $bg_dark !important;
        color: $text-primary;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          right: -5px;
          z-index: -1;
        }
        &::before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          left: -5px;
          z-index: -1;
        }
      }
      &--selecting-range-start {
        &:hover {
          color: $white !important;
        }
        &:not(.react-datepicker__day--selecting-range-end)::after {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          right: -5px;
          z-index: -1;
        }
        &::before {
          content: unset;
        }
      }
      &--selecting-range-end {
        &:not(.react-datepicker__day--selecting-range-start)::before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          left: -5px;
          z-index: -1;
        }
        &::after {
          content: unset;
        }
      }

      // in-range는 선택 후 범위
      &--in-range:hover {
        border-radius: 100% !important;
      }

      &--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
        background-color: $bg_dark !important;
        color: $text-primary;
        border-radius: 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          right: -3px;
          z-index: -1;
        }
        &::before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          left: -3px;
          z-index: -1;
        }
      }
      &--range-start {
        background-color: $text-primary;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          right: -5px;
          z-index: -1;
        }
      }
      &--range-end {
        background-color: $text-primary;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          background-color: $bg_dark;
          top: -1px;
          left: -5px;
          z-index: -1;
        }
      }

      &-names {
        margin-top: 2px;
      }

      &-name {
        font-size: 1.2rem;
        width: 22px;
        height: 22px;
        padding: 4px;
        color: $white;
      }

      &--today {
        border: 1px solid transparent;
        font-weight: 400;
      }

      &--selected {
        background-color: $text-primary;
        border: 1px solid $text-primary;
        color: $white;

        &:hover {
          border-radius: 100%;
          background-color: $white;
        }
      }

      &--keyboard-selected {
        background-color: $text-primary !important;
        color: $white;

        &:hover {
          background-color: $white;
          color: $white !important;
          border: 1px solid $text-primary;
        }
        &.react-datepicker__day--range-start:hover {
          background-color: $white !important;
          color: $text-primary !important;
          border: 1px solid $text-primary;
        }
      }

      // 달력에서 날짜 선택해야 마크 날짜에 마크 적용
      // &--keyboard-selected:not(.react-datepicker__day--selected) {
      //   background-color: transparent !important;
      //   color: inherit !important;
      // }
    }
  }
  .react-datepicker__navigation-icon {
    font-size: 12px;
    &::before {
      border-width: 2px 2px 0 0;
      width: 6px;
      height: 6px;
    }
  }
  .react-datepicker__navigation--previous {
    width: 20px;
  }
  .react-datepicker__navigation--next {
    width: 20px;
  }
  .react-datepicker__navigation--next--with-time {
    right: 2px !important;
  }
}

.react-datepicker-popper {
  z-index: $date-picker-z-in !important;
}

.end-date-picker {
  .react-datepicker__day {
    &--keyboard-selected:not(.react-datepicker__day--selected) {
      background-color: transparent !important;
      color: inherit !important;
    }
  }
}

.datepicker-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  cursor: pointer;

  white-space: nowrap;
  box-sizing: border-box;

  input {
    border: 1px solid $text-primary;
    padding: 10px;
    border: none;
    flex: 1;
    height: 100%;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    &:disabled {
      background-color: $bg_dark;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      & ~ .icon {
        background-color: $bg_dark;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  &:hover {
    border: 1px solid $text-primary;
  }

  .icon {
    width: 36px;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../../images/ic_calendar_b.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.range-datepicker-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-width: 200px;

  cursor: pointer;

  white-space: nowrap;
  box-sizing: border-box;

  .react-select__control {
    transition: unset;
  }

  input {
    padding: 10px;
    padding-right: 0;
    border: none;
    flex: 1;
    height: 100%;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    &:disabled {
      background-color: $bg_light;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      & ~ .icon {
        background-color: $bg_light;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  &:hover {
    border: 1px solid $text-primary;
  }

  .icon {
    width: 36px;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../../images/ic_calendar_b.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  &.with-search {
    position: relative;
    &:after {
      content: "";
      width: 36px;
      height: 38px;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../images/ic_calendar_b.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-color: $white;
    }
    &.base-search-input__focus {
      border-color: $text-primary;
    }

    .react-datepicker-wrapper input {
      min-width: 196px;
      padding-right: 36px;
    }

    .base-select {
      .react-select__control {
        border-color: transparent;
        border: none;
        background-color: transparent;

        &--is-focused {
          border: none;
          border-color: transparent;
          box-shadow: unset;
        }
      }
    }
  }
}

// 텍스트에리아
.base-textarea {
  width: 100%;
  position: relative;

  textarea {
    width: 100%;
    min-height: 80px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $line_light;
    padding: 10px;
    color: map-get($grays, "900");
    font-size: 1.4rem;
    resize: none;
    font-family: "Apple SD Gothic Neo", sans-serif;

    //  &::-webkit-scrollbar {
    //     display: none;
    //   }
    // @include font;

    &::placeholder {
      color: $text-primary4;
      font-weight: 400;
    }

    &:focus,
    &:focus:hover {
      outline: none;
      border: 1px solid $text-primary;
    }

    &:hover {
      border-color: $text-primary;
    }

    &:disabled {
      background-color: $bg_light;
      color: $text-primary4;
      &:hover {
        border-color: $line_light;
      }
    }
    &:read-only {
      background-color: $bg_light;
      &:hover {
        border-color: $line_light;
      }
    }
  }

  &.admin-memo {
    textarea {
      min-height: 80px;
      height: 88px;
    }
  }

  .now-max-length {
    position: absolute;
    right: 20px;
    bottom: 8px;
    background-color: transparent;
    color: map-get($grays, "500");
    font-size: 11px;

    span {
      font-size: 1.1rem;
    }
  }
}

// 체크박스
input[type="checkbox"] {
  & + label {
    line-height: 1.6;
  }
  & + label .base-checkbox {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    border: 1px solid $line-light;
    margin-top: 1px;

    &:hover {
      border: 1px solid $text-primary4;
    }
  }
  & + label {
    line-height: 1.5;
  }

  &:checked + label .base-checkbox {
    background: url("../../images/ic_checkbox_checked.svg");
    background-size: 20px;
    background-position: center;
    border: none;
  }

  &:disabled + label .base-checkbox {
    background-color: $bg_dark;
    border: 1px solid $line-light;
  }

  &:disabled:checked + label .base-checkbox {
    background: url("../../images/ic_checkbox_checked_disabled.svg");
    background-size: 20px;
    background-position: center;
    border: none;
  }
}

// 라디오버튼
input[type="radio"] {
  & + label .base-radio {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    border: 1px solid $line_dark;
    margin-top: 1px;
    position: relative;
    background-color: $white;

    &:hover {
      border: 1px solid $text-primary;
    }
  }

  &:checked + label .base-radio {
    border: 1px solid $text-primary;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $text-primary;
    }
  }

  &:disabled + label .base-radio {
    background-color: $bg_dark;
    border: 1px solid $line-dark;
  }
  &:checked:disabled + label .base-radio {
    background-color: $bg_dark;
    border: 1px solid $line_dark;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $text_primary5;
    }
  }
}

// 체크박스 칩형태
.chip-case input[type="checkbox"] {
  & + label {
    height: 30px;
    width: fit-content;
    min-width: 45px;
    border-radius: 50px;
    border: 1px solid $line-light;
    display: inline-block;
    background-color: $white;
    position: relative;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid $text-primary;
      span {
        color: $text-primary;
      }
    }

    span {
      font-size: 1.2rem !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
      line-height: 16px;
      color: $text-primary;
    }
  }

  & + label .base-checkbox {
    display: none;
  }

  &:checked + label {
    background-image: unset;
    background-color: $text-primary;
    border: 1px solid $text-primary;
    span {
      color: $white;
    }
  }

  &:disabled + label {
    background-color: $line-light;
    border: 1px solid $line-light;
    span {
      color: $white;
    }
  }

  &:checked:disabled + label {
    background-color: $line-dark;
    border: 1px solid $line-dark;
    span {
      color: $white;
    }
  }
}

// 라디오 칩형태
.chip-case input[type="radio"] {
  & + label {
    height: 30px;
    width: fit-content;
    min-width: 45px;
    border-radius: 50px;
    border: 1px solid $line-light;
    display: flex;
    background-color: $white;
    position: relative;
    align-items: center;
    justify-content: center;
    &:hover {
      border: 1px solid $text-primary4;
      span {
        color: $text-primary;
      }
    }
    span {
      font-size: 1.2rem !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
      line-height: 16px;
      color: $text-primary;
    }
    div {
      margin: 0 !important;
    }
  }

  & + label .base-radio {
    display: none;
  }

  &:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: unset;
    background-color: $text-primary;
    border: 1px solid $text-primary;
    color: $white;
    span {
      color: $white;
    }
    div {
      margin: 0 !important;
    }
  }

  &:disabled + label {
    background-color: $line-light;
    border: 1px solid $line-light;
    color: $white;
    span {
      color: $white;
    }
    div {
      margin: 0 !important;
    }
  }

  &:checked:disabled + label {
    background-color: $line-dark;
    border: 1px solid $line-dark;
    color: $white !important;
    span {
      color: $white;
    }
    div {
      margin: 0 !important;
    }
  }
}

// squre 형태 라디오
.base-square-radio {
  display: flex;
  align-items: center;
  background-color: $line-light;
  border-radius: 4px;
  input[type="radio"] {
    & + label {
      height: 40px;
      width: fit-content;
      min-width: 80px;
      border-radius: 4px;
      border: 1px solid $line-light;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      position: relative;
      color: $text-primary3;
      align-items: center;

      div {
        margin-left: 0 !important;
      }
      &:hover {
        border: unset;
        span {
          color: unset;
        }
      }

      span {
        font-size: 1.2rem !important;
        margin: 0 !important;
        line-height: 16px;
        color: $text-primary;
      }
    }

    & + label .base-radio {
      display: none;
    }

    &:checked + label {
      background-image: unset;
      background-color: $white;
      border: 1px solid $line-light;
      color: $text-primary;
    }

    // &:disabled+label {
    //   background-color: $line-light;
    //   border: 1px solid $line-light;
    //   span{
    //     color:$white;
    //   }
    // }

    // &:checked:disabled+label {
    //   background-color: $line-dark;
    //   border: 1px solid $line-dark;
    //   span{
    //     color:$white;
    //   }
    // }
  }
}

// mobile
.base-textarea-mobile {
  width: 100%;
  height: auto;
  min-height: 54px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1.6rem;
  color: $text-primary;
  resize: none;
  font-family: "Apple SD Gothic Neo", sans-serif;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $text-primary4;
  }

  &.double-line {
    padding: 5px 10px;
  }

  &__wrap {
    width: 100%;
    height: auto;
    min-height: 54px;
    background: #f8f8fa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1.6rem;
    color: $text-primary;
    resize: none;
    font-family: "Apple SD Gothic Neo", sans-serif;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0;
  }

  &.fixed-height {
    max-height: 54px;
  }

  &:disabled,
  &:read-only {
    color: $text-primary4;
  }
}

.base-input-wrap-mobile {
  width: 100%;
  position: relative;

  &__suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 1.6rem;
    transform: translateY(-50%);
  }

  .search-btn,
  .clear-btn {
    position: absolute;
    right: 7px;
    top: 7px;
  }
}

input.base-input-file {
  &:disabled {
    & ~ .contents__img-add {
      &::after {
        opacity: 0.3;
      }
    }
  }
}

input.base-input-mobile {
  width: 100%;
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px 10px;
  font-size: 1.6rem;
  color: $text-primary;
  font-family: "Apple SD Gothic Neo", sans-serif;

  &::placeholder {
    color: $text-primary4;
    font-size: 1.6rem;
  }

  &:focus {
    outline: none;
  }

  &.no-border {
    border: none;
  }

  &:disabled,
  &:read-only {
    color: $text-primary4;

    & ~ .base-input-wrap-mobile__suffix {
      color: $text-primary4;
    }
  }
}

input[type="checkbox"] {
  & + label .base-checkbox-mobile {
    height: 30px;
    width: 30px;
    display: inline-block;
    padding: 0;
    margin-top: 1px;
    background: url("../../images/ic_checkbox.svg") no-repeat;
    background-size: 24px;
    background-position: center;
    border: none;
    cursor: pointer;
  }

  // &:disabled + label .base-checkbox {
  //   border: none;
  // }

  &:checked + label .base-checkbox-mobile {
    background: url("../../images/ic_checkbox_checked.svg") no-repeat;
    background-size: 24px;
    background-position: center;
  }
}

input[type="radio"] {
  & + label .base-radio-mobile {
    height: 30px;
    width: 30px;
    display: inline-block;
    padding: 0;
    margin-top: 1px;
    background: url("../../images/ic_checkbox.svg") no-repeat;
    background-size: 24px;
    background-position: center;
    border: none;
    cursor: pointer;
  }

  // &:disabled + label .base-checkbox {
  //   border: none;
  // }

  &:checked + label .base-radio-mobile {
    background: url("../../images/ic_checkbox_checked.svg") no-repeat;
    background-size: 24px;
    background-position: center;
  }
}

.new-base-chip {
  min-width: 50px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 50px;
  line-height: 1.6;
  padding-bottom: 1px;
  white-space: nowrap;
  background-color: $white;
  color: $text-primary;
  border: 1px solid $line-light;
  cursor: pointer;

  &.selected {
    background-color: $text-primary;
    color: $white;
    border-color: $text-primary;
  }
  &:disabled {
    color: $text_primary5;
    border-color: $line_light;
    background-color: $white;
    &.selected {
      color: $white;
      background-color: $line-light;
    }
  }
}
