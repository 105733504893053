.key-card-modal {
  .title {
    font-size: 1.4rem;
    letter-spacing: -0.04em;
    color: $text-primary3;
  }

  .link-before {
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  .link-warp {
    display: block; /* 인라인 블록으로 설정 */
    text-align: left;
    padding: 10px; /* 여백 추가 */
    background-color: #f2f4f6; /* 배경색 설정 */
    border-radius: 5px; /* 모서리 둥글게 */
  }
}
