.reservation-calendar-page {
  $base-width: 1920; // 기준 해상도..

  $header-layout-gap: 18px;
  $logo-area-height: 44px;
  $cell-height: 45px; //구두로 결정
  $calendar-board-height: calc(100vh - (#{$header-layout-gap} + 106px + #{$logo-area-height}));
  $timeline-width: 178px;

  height: 100vh;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 40px;
  padding-bottom: 66px;
  background-color: #fff;
  background-image: url("../../images/display_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: $header-layout-gap;
  overflow: hidden;

  .logo-area {
    height: $logo-area-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding-left: 10px;
    padding-right: 12px;

    &__left {
      display: flex;
      align-items: center;
      &-logo {
        width: 100%;
        height: 44px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 18px;
        &.gs {
          width: 44px;
          background-image: url("../../images/logo_gs.svg");
        }
        &.fact {
          width: 234px;
          background-image: url("../../images/display_fact_logo.svg");
        }
        &.pinpoint {
          width: 160px;
          background-image: url("../../images/display_pinpoint_logo.svg");
        }
      }
      h2 {
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.06em;
        white-space: nowrap;
        // width: 100%;
      }
    }
    &__right {
      p {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .reservation-calendar {
    flex: 1;
    background-color: #f5f5f5;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 6px 20px 6px #01010126;
    border-radius: 12px;

    .swiper {
      height: 100%;
      width: 100% !important;
      overflow: hidden;
    }

    .swiper-container {
      height: 100%;
    }
    .swiper-slide {
      display: grid;
      grid-template-rows: minmax(100px, 1fr) minmax(100px, 4.318fr);
      // height: $calendar-board-height;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100% !important;
    }

    &-header {
      background-color: #fff;
      padding: 20px 0px;

      border-bottom: 1px solid #eaeaea;
      flex-shrink: 0;
      border-bottom: 8px solid #cacaca;

      &-content {
        height: 100%;
        display: grid;
        align-items: center;
        // grid-template-columns: 1fr 9.236fr;
        grid-template-columns: $timeline-width 1fr;
        // gap: 20px;
        min-height: 0;
      }

      &-qr {
        width: 100%; /* 부모 요소의 너비에 따라 늘어남 */
        height: 100%;
        max-height: 100%;
        padding-left: 28px;
        padding-right: 7px;

        .qr-wrap {
          width: 130px;
          height: 130px;
          object-fit: contain; /* 또는 cover */
          border-radius: 4px;
          // border: 1px solid #bebebe;

          // outline: 1px solid #000;
          // outline-offset: 1px;

          box-shadow: inset 0 0 0 1px #bebebe;

          background-color: #fff;
          padding-top: 12px;
          position: relative;
          overflow: hidden;

          .qr {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          }
          .qr-info {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 2px);
            height: 30px;
            background-color: #f2f4f6;
            position: relative;
            margin-bottom: 1px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            p {
              font-size: 13px;
              padding-left: 1px;
            }
            &::before {
              content: "";
              width: 33px;
              height: 11px;
              background-image: url("../../images/logo_display_taap.svg");
              background-repeat: none;
            }
          }
        }
      }

      &-rooms {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        height: 100%;
        gap: 0px;
        width: 100%;
        // padding-left: 7px;
        padding-right: 28px;
      }

      &-room {
        border-radius: 8px;
        padding-left: 7px;
        padding-right: 7px;
        &-box {
          position: relative;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #a0a0a0;
          height: 130px;
          min-height: 130px;
          max-height: 130px;
          padding: 8px 15px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(50, 50, 50, 0) 0%, #323232 100%);
            // background-image: url("../../images/empty_facility.svg");
          }
          &.empty-images {
            background-image: url("../../images/empty_facility.svg") !important;
            &::before {
              background: linear-gradient(180deg, rgba(50, 50, 50, 0) 0%, #323232 100%);
            }
          }
          &.empty-facility {
            background-image: url("../../images/empty_facility.svg") !important;
            &::before {
              display: none;
            }
          }
        }

        &-capacity {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #666;
          margin-bottom: 4px;
          .capacity-chip {
            padding-left: 30px;
            padding-right: 11px;
            background: #070707b2;
            background-blend-mode: multiply;
            border-radius: 80px;
            display: flex;
            align-items: center;
            height: 28px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              background-image: url("../../images/ic_user_w.svg");
              background-repeat: no-repeat;
              left: 10px;
            }
          }
          .number {
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            line-height: 20px;
            padding-bottom: 2px;
          }
        }

        &-name {
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          z-index: 1;
          @include ellipsis(1);
        }

        &-building {
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          z-index: 1;
          @include ellipsis(1);
        }
      }
    }

    &-content {
      flex: 1;
      position: relative;
      overflow: hidden;
      position: relative;

      &-scroll {
        height: 100%;
        display: grid;
        grid-template-columns: $timeline-width 1fr;
        // grid-template-columns: 1fr 9.236fr;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fff;
        position: relative;
        /* 스크롤바가 영역을 차지하도록 설정 */
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 28px; /* 스크롤바 너비와 동일하게 */
          height: 100%;
          pointer-events: none; /* 클릭 이벤트 통과 */
        }
        &::-webkit-scrollbar {
          width: 28px;
          display: block; /* 명시적으로 표시 */
        }

        &::-webkit-scrollbar-track {
          background-color: #fff; //임시 레드로변경
          display: block; /* 명시적으로 표시 */
        }

        &::-webkit-scrollbar-thumb {
          background: #e0e0e0;
          border-radius: 14px;
          border: 11px solid #fff;
          display: block; /* 명시적으로 표시 */
        }
      }
    }

    &-time {
      width: 100%;
      flex-shrink: 0;
      background-color: #fff;
      z-index: 1;

      &-slot {
        height: $cell-height;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        color: #666;
        position: relative;

        &:nth-child(odd) {
          color: #191919;
        }

        &:nth-child(even) {
          color: #999999;
        }
        p {
          position: absolute;
          top: 29px;
        }
      }
    }

    &-grid {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      overflow: hidden;
      position: relative;

      &-column {
        position: relative;
        border-right: 1px solid #d3cfcf;
        &:first-child {
          border-left: 1px solid #d3cfcf;
        }
        &:first-child .reservation-calendar-grid-column-cell {
          &:nth-child(even)::before {
            border-bottom: 1px dashed #d3cfcf;
          }
          &::before {
            content: "";
            width: 26px;
            height: 1px;
            border-bottom: 1px solid #d3cfcf;
            position: absolute;
            left: -26px;
            bottom: -1px;
            z-index: 1;
          }
        }
        &:last-child {
          border-right: none;
        }
        &.empty-column {
          background-color: #f1f1f1;
        }
        &-cell {
          height: $cell-height;
          position: relative;

          &:nth-child(odd) {
            border-bottom: 1px solid #d3cfcf;
          }

          &:nth-child(even) {
            border-bottom: 1px dashed #d3cfcf;
          }
        }
      }
    }

    &-current-time {
      position: absolute;
      width: calc(100% + 34px);
      left: $timeline-width - 34px;
      pointer-events: none;
      z-index: 2;

      &-line {
        width: calc(100% - #{$timeline-width} + 26px);
        height: 2px;
        background-color: #4177eb;
      }

      &-label {
        position: absolute;
        left: 0;
        transform: translate(-100%, -50%);
        width: 110px;
        height: 36px;
        font-weight: 600;
        font-size: 18px;
        background-color: #4177eb;
        border-radius: 80px;
        line-height: 26px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-navigation {
      position: fixed;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      gap: 20px;
      z-index: 2;

      &-button {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #b2b5b5;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        &:first-child::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: 50%;
          z-index: 1;
          background: url("../../images/ic_arrow_left.svg") 0 0 repeat;
          transform: translate(-50%, -50%);
        }
        &:last-child::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: 50%;
          z-index: 1;
          background: url("../../images/ic_arrow_right.svg") 0 0 repeat;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: #f8f9fa;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &-page {
        height: 38px;
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #191919;
        line-height: 26px;
      }
    }

    .reservation-box {
      width: 100%;
      padding: 8px 20px;
      min-height: $cell-height;
      position: relative;
      top: $cell-height;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 2px #00000033;
      cursor: pointer;
      z-index: 3;
      overflow: hidden;
      &.full-reservation {
        top: 0;
        border-radius: 0;
      }
      &.before-today-reservation {
        top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-title {
        width: 100%;
        font-weight: 800;
        font-size: 18px;
        line-height: 26px;
        @include ellipsis;
      }
      &-first-info,
      &-third-info,
      &-second-info {
        font-size: 14px;
        font-weight: 500;
      }
      &-first-info {
        margin-top: 6px;
      }

      &.on {
        border: 1.6px solid #4177eb;
        background-color: #4177eb;
        p {
          color: #fff;
        }
      }
      &.ready {
        border: 1.6px solid #4177eb;
        background-color: #ffffff;
        p {
          color: #191919;
        }
      }

      &.off {
        opacity: 0.5;
        border: 1px solid #bebebe;
        background-color: #ededed;
        p {
          color: #191919;
        }
      }
    }
  }
  .reservation-calendar-extra {
    width: fit-content;
    position: fixed;
    bottom: 13px;
    right: 35px;
    display: flex;
    gap: 20px;
    align-items: center;
    &-left {
      width: fit-content;
      position: fixed;
      bottom: 13px;
      right: 35px;
      display: flex;
      gap: 20px;
      align-items: center;
      left: 35px;
      right: unset;
    }
    .extra-notice {
      color: $text-primary3;
      font-size: 13px;
      line-height: 20px;
    }
    button,
    a {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 1px solid #b2b5b5;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;
    }
    &-reload {
      background-image: url("../../images/ic_reload.svg");
      background-size: 26px 26px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &-setting {
      background-image: url("../../images/ic_setting_display.svg");
      background-size: 26px 26px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &-language {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.reservation-calendar-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: red;
  opacity: 0.5;
  z-index: $dim-z-in;
}
